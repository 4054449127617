import {
  BrowserIcon,
  DiscordIcon,
  FacebookIcon,
  GithubIcon,
  InstagramIcon,
  Link,
  MediumIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  YoutubeIcon,
} from '@pancakeswap/uikit'
import { cloneElement } from 'react'
import { styled } from 'styled-components'

const CommunityWrapper = styled.div`
  display: flex;
  margin-top: 30px;
  gap: 15px;
  justify-content: center;
  flex-wrap: wrap;
`
const CommunityTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  background-color: ${({ theme }) => theme.colors.backgroundAlt};
  width: 32px;
  height: 32px;
  transition: background-color 0.25s ease-in-out;
  svg path {
    transition: fill 0.25s ease-in-out;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 48px;
    height: 48px;
  }
  svg circle {
    fill: ${({ theme }) => theme.colors.orange};
  }
  svg path {
    fill: ${({ theme }) => theme.colors.primary};
  }
  svg path.youtube {
    fill: ${({ theme }) => theme.colors.orange};
  }
`
const communityData = [
  // { icon: <FacebookIcon />, href: '', alt: 'FacebookIcon' },
  { icon: <TwitterIcon />, href: 'https://x.com/strswap', alt: 'TwitterIcon' },
  { icon: <TelegramIcon />, href: 'https://t.me/strswap', alt: 'TelegramIcon' },
  // { icon: <RedditIcon />, href: '', alt: 'RedditIcon' },
  // { icon: <DiscordIcon />, href: '', alt: 'DiscordIcon' },
  // { icon: <MediumIcon />, href: '', alt: 'MediumIcon' },
  // { icon: <GithubIcon />, href: '', alt: 'GithubIcon' },
  // { icon: <BrowserIcon />, href: '', alt: 'BrowserIcon' },
  // { icon: <InstagramIcon />, href: '', alt: 'InstagramIcon' },
  // { icon: <YoutubeIcon />, href: '', alt: 'YoutubeIcon' },
]

export const CommunityTags: React.FC = () => {
  return (
    <CommunityWrapper>
      {communityData.map((item) => (
        <Link href={item.href} key={item.alt} external>
          <CommunityTag>{cloneElement(item.icon)}</CommunityTag>
        </Link>
      ))}
    </CommunityWrapper>
  )
}
