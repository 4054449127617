import { useTranslation } from '@pancakeswap/localization'
import { OpenNewIcon, useMatchBreakpoints } from '@pancakeswap/uikit'
import {
  BackgroundGraphic,
  BannerActionContainer,
  BannerContainer,
  BannerDesc,
  BannerGraphics,
  BannerMain,
  BannerTitle,
  CoBrandBadge,
  GraphicDetail,
  LinkExternalAction,
} from '@pancakeswap/widgets-internal'
import { ASSET_CDN } from 'config/constants/endpoints'
import { styled } from 'styled-components'

const VerticalDivider = styled.span`
  background: #000000;
  width: 1px;
  height: 1rem;
  margin: auto 8px;
`

const bgMobile = `/images/banners/bg-banner-mobile.png`
const bgDesktop = `/images/banners/bg-banner-desktop.png`
const coBrandLogo = `${ASSET_CDN}/web/banners/masa-trading-competition/cobrand-logo.png`

const bgSmVariant: GraphicDetail = {
  src: 'none',
  width: 272,
  height: 224,
}

const bgXsVariant: GraphicDetail = {
  src: 'none',
  width: 218,
  height: 182,
}

const ButtonStart = styled.a`
  display: flex;
  background-color: #ffffff;
  padding: 13px 20px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #00AE73;
  border-radius: 12px;
  margin-top: 15px;
  font-size: 16px;
  font-weight: 600;
`

const participateLink = 'https://strswap.finance/swap?outputCurrency=0x74a33d9E44b81673a4F0B5a4294fA0e5e2ec5877&chainId=59671'

export const BannerStart = () => {
  const { t } = useTranslation()
  const { isMobile } = useMatchBreakpoints()

  const participateAction = (
    <ButtonStart href={participateLink}>
      {t('Buy STR')}
      <OpenNewIcon color="rgba(51, 178, 136, 1)" marginLeft="5px" />
    </ButtonStart>
  )
  return (
    <BannerContainer background="linear-gradient(100deg, rgba(51, 178, 136, 1) 25%, rgba(7, 44, 116, 1) 102.8%)">
      <BannerMain
        containerStyle={{ justifyContent: 'center' }}
        title={<BannerTitle variant="white">{t('STR Coin Now Live on zkSync Era!')}</BannerTitle>}
        desc={
          isMobile ? null : <BannerDesc color="#FFFFFF">{t('Swap and Provide Liquidity on zkSync Era Now')}</BannerDesc>
        }
        actions={<BannerActionContainer>{participateAction}</BannerActionContainer>}
      />
      <BannerGraphics>
        <BackgroundGraphic src={bgDesktop} width={500} height={224} sm={bgSmVariant} xs={bgXsVariant} />
      </BannerGraphics>
    </BannerContainer>
  )
}
