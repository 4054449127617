import { useTranslation } from '@pancakeswap/localization'
import { Box, Flex, Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { ASSET_CDN } from 'config/constants/endpoints'
import { keyframes, styled } from 'styled-components'
import { useAccount } from 'wagmi'
import SunburstSvg from './SunburstSvg'

const Image = styled.img``

const floatingAnim = (x: string, y: string) => keyframes`
  from {
    transform: translateX(0px) translateY(0px);
  }
  50% {
    transform: translate(${x}) translateY(${y});
  }
  to {
    transform: translateX(0px) translateY(0px);
  }
`

const ImageWrapper = styled.div`
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  > * {
    will-change: transform;
  }
  .pancake {
    position: absolute;
    top: 20px;
    left: -40px;
    display: none;
    animation: ${floatingAnim('3px', '2px')} 3s ease-in-out 1s infinite;
    ${({ theme }) => theme.mediaQueries.lg} {
      display: block;
      top: 40%;
      left: calc(40% - 60px - 300px);
    }
    ${({ theme }) => theme.mediaQueries.xl} {
      display: block;
      top: calc(50% - 50px);
      left: calc(40% - 60px - 300px);
    }
  }
  .rz-coin {
    position: absolute;
    top: 20px;
    left: -40px;
    display: none;
    animation: ${floatingAnim('3px', '2px')} 3s ease-in-out 1s infinite;
    ${({ theme }) => theme.mediaQueries.lg} {
      display: block;
      top: 25%;
      left: calc(38% - 60px - 300px);
    }
    ${({ theme }) => theme.mediaQueries.xl} {
      display: block;
      top: 25%;
      left: calc(38% - 60px - 300px);
    }
  }
  .image-sun {
    position: absolute;
    top: 20px;
    left: -40px;
    display: none;
    animation: ${floatingAnim('3px', '2px')} 3s ease-in-out 1s infinite;
    ${({ theme }) => theme.mediaQueries.xl} {
      display: block;
      top: 50%;
      left: calc(10% - 50px);
    }
  }
  .rock {
    position: absolute;
    top: 16px;
    right: 5px;
    animation: ${floatingAnim('3px', '3px')} 3s ease-in-out 0.5s infinite;
    display: none;
    ${({ theme }) => theme.mediaQueries.lg} {
      display: block;
      top: 40%;
      left: calc(65% - 60px + 240px);
    }
    ${({ theme }) => theme.mediaQueries.xl} {
      display: block;
      top: 50%;
      left: calc(60% - 60px + 240px);
    }
  }
  .big-pancake {
    position: absolute;
    top: 10px;
    right: -60px;
    animation: ${floatingAnim('8px', '6px')} 3s ease-in-out 2.5s infinite;
    display: none;
    ${({ theme }) => theme.mediaQueries.lg} {
      display: block;
      top: 25%;
      right: calc(10% - 50px);
    }
    ${({ theme }) => theme.mediaQueries.xl} {
      display: block;
      top: 45%;
      right: calc(10% - 20px);
    }
  }
  .rock2 {
    position: absolute;
    bottom: 10px;
    right: 20px;
    animation: ${floatingAnim('1px', '1px')} 3s ease-in-out 3.5s infinite;
    display: none;
    ${({ theme }) => theme.mediaQueries.xl} {
      display: block;
      top: calc(45% - 70px);
      right: calc(20% - 60px);
    }
  }
`

const ImageBox: React.FC = () => {
  return (
    <ImageWrapper>
      <Image className="pancake" src="/images/footer/rz-1.png" alt="rz-1" />
      <Image className="rz-coin" src="/images/footer/rz-2.png" alt="rz-2" />
      <Image className="image-sun" src="/images/footer/image-sun.png" alt="sun" />
      <Image className="rock" src="/images/footer/rz-3.png" alt="rz-3" />
      <Image className="big-pancake" src="/images/footer/rz-4.png" alt="rz-4" />
      <Image className="rock2" src="/images/footer/image-star.png" alt="star" />
    </ImageWrapper>
  )
}

const BgWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1;
`

const StyledSunburst = styled(SunburstSvg)`
  height: 100%;
  width: 100%;
  transform: scale3d(3.5, 3.5, 1);
  transform-origin: center center;
  ${({ theme }) => theme.mediaQueries.xl} {
    transform: scale3d(4, 4, 1);
  }
`

const Wrapper = styled(Flex)`
  width: 100%;
  z-index: 2;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 330px;
`

const Footer = () => {
  const { t } = useTranslation()
  const { address: account } = useAccount()
  const { isMobile } = useMatchBreakpoints()

  return (
    <Box>
      {/* <BgWrapper>
        <ImageBox />
      </BgWrapper> */}

      <Wrapper>
        <Text mb="24px" fontWeight={600} color="#F4EEFF" textAlign="center" fontSize={isMobile ? 32 : 40}>
          {t("Join Everyone's Favorite DEX Now!")}
        </Text>
        {!account && <ConnectWalletButton variant="light" color="primary" mt="24px" />}
      </Wrapper>
    </Box>
  )
}

export default Footer
