import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg width="20" height="19" viewBox="0 0 20 19" fill="none" {...props}>
    <path
      d="M8.85096 15.1918C8.88808 15.3032 8.8982 15.4218 8.8805 15.5378C8.8628 15.6539 8.81778 15.764 8.74914 15.8592C8.68051 15.9545 8.59023 16.032 8.48574 16.0855C8.38126 16.139 8.26556 16.1669 8.14818 16.1668H0.740794C0.623416 16.1669 0.507717 16.139 0.403231 16.0855C0.298746 16.032 0.208466 15.9545 0.139831 15.8592C0.071197 15.764 0.0261737 15.6539 0.00847205 15.5378C-0.00922961 15.4218 0.000897202 15.3032 0.0380179 15.1918L3.74171 4.08077C3.79085 3.93324 3.88517 3.80492 4.01131 3.71399C4.13744 3.62305 4.28899 3.57412 4.44449 3.57412C4.59998 3.57412 4.75153 3.62305 4.87767 3.71399C5.0038 3.80492 5.09812 3.93324 5.14726 4.08077L8.85096 15.1918ZM17.7778 5.42613C17.7778 4.47385 17.4954 3.54296 16.9663 2.75117C16.4373 1.95938 15.6853 1.34226 14.8055 0.977835C13.9257 0.613414 12.9576 0.518065 12.0237 0.703845C11.0897 0.889626 10.2318 1.34819 9.5584 2.02155C8.88504 2.69492 8.42648 3.55283 8.2407 4.48681C8.05492 5.42079 8.15026 6.38889 8.51469 7.26868C8.87911 8.14847 9.49623 8.90044 10.288 9.42949C11.0798 9.95855 12.0107 10.2409 12.963 10.2409C14.2395 10.2395 15.4633 9.73172 16.3659 8.82909C17.2686 7.92645 17.7763 6.70265 17.7778 5.42613ZM19.2593 11.7224H11.1111C10.9147 11.7224 10.7263 11.8005 10.5874 11.9394C10.4484 12.0783 10.3704 12.2667 10.3704 12.4632V17.6483C10.3704 17.8448 10.4484 18.0332 10.5874 18.1721C10.7263 18.311 10.9147 18.3891 11.1111 18.3891H19.2593C19.4557 18.3891 19.6441 18.311 19.783 18.1721C19.922 18.0332 20 17.8448 20 17.6483V12.4632C20 12.2667 19.922 12.0783 19.783 11.9394C19.6441 11.8005 19.4557 11.7224 19.2593 11.7224Z"
      fill="#00AE73"
    />
  </Svg>
);

export default Icon;
