import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="24" height="20" viewBox="0 0 24 20" fill="none" {...props}>
      <path
        d="M19.7385 11.9061C18.7796 12.489 17.6782 12.7956 16.556 12.792C15.6159 12.7845 14.6864 12.5922 13.8205 12.2261C13.1531 13.1682 12.796 14.2949 12.7987 15.4495V18.398C12.7989 18.5077 12.7766 18.6162 12.7331 18.7169C12.6897 18.8175 12.626 18.9082 12.546 18.9832C12.466 19.0582 12.3715 19.116 12.2682 19.1529C12.165 19.1898 12.0552 19.2051 11.9458 19.1979C11.7402 19.18 11.549 19.085 11.4105 18.932C11.272 18.7791 11.1964 18.5794 11.1989 18.373V17.1292L7.33759 13.2679C6.76358 13.482 6.15652 13.594 5.54389 13.5988C4.7005 13.6009 3.87291 13.3701 3.15229 12.9319C0.973655 11.6082 -0.199148 8.56167 0.0278138 4.7793C0.0392413 4.58362 0.122125 4.39895 0.260729 4.26034C0.399332 4.12174 0.584006 4.03886 0.779688 4.02743C4.56205 3.80447 7.60854 4.97327 8.92832 7.1519C9.44685 8.00584 9.67322 9.0054 9.57321 9.99943C9.567 10.0764 9.5386 10.15 9.49147 10.2112C9.44433 10.2725 9.38046 10.3187 9.3076 10.3444C9.23473 10.3701 9.15598 10.3741 9.08086 10.356C9.00575 10.3379 8.93749 10.2985 8.88433 10.2424L6.96465 8.23272C6.81343 8.08905 6.61206 8.01014 6.40349 8.01281C6.19491 8.01548 5.99563 8.09952 5.84813 8.24702C5.70064 8.39452 5.6166 8.59379 5.61392 8.80237C5.61125 9.01094 5.69017 9.21231 5.83384 9.36353L11.2209 14.8876C11.2269 14.8096 11.2339 14.7316 11.2419 14.6546C11.4168 13.1716 12.0712 11.7863 13.1056 10.7093L18.1638 5.3642C18.3139 5.21425 18.3982 5.01081 18.3983 4.79865C18.3984 4.58649 18.3142 4.38298 18.1643 4.23289C18.0143 4.08281 17.8109 3.99844 17.5987 3.99834C17.3866 3.99825 17.1831 4.08244 17.033 4.23239L12.1338 9.41352C12.0847 9.46547 12.0227 9.50333 11.9541 9.52317C11.8854 9.54301 11.8128 9.54411 11.7436 9.52635C11.6744 9.5086 11.6112 9.47263 11.5606 9.4222C11.51 9.37176 11.4739 9.30869 11.4559 9.23955C10.982 7.49185 11.1909 5.75214 12.0958 4.25839C13.8815 1.31088 18.0368 -0.266852 23.2119 0.037097C23.4076 0.0485245 23.5923 0.131408 23.7309 0.270012C23.8695 0.408616 23.9524 0.593289 23.9638 0.788971C24.2638 5.9651 22.686 10.1204 19.7385 11.9061Z"
        fill="#00AE73"
      />
    </Svg>
  );
};

export default Icon;
