import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg width="20" height="15" viewBox="0 0 20 15" fill="none" {...props}>
    <path
      d="M18.5714 0.357178H1.42857C1.04969 0.357178 0.686328 0.507687 0.418419 0.775597C0.15051 1.04351 0 1.40687 0 1.78575V13.2143C0 13.5932 0.15051 13.9566 0.418419 14.2245C0.686328 14.4924 1.04969 14.6429 1.42857 14.6429H18.5714C18.9503 14.6429 19.3137 14.4924 19.5816 14.2245C19.8495 13.9566 20 13.5932 20 13.2143V1.78575C20 1.40687 19.8495 1.04351 19.5816 0.775597C19.3137 0.507687 18.9503 0.357178 18.5714 0.357178ZM10.7143 11.7857H9.28571C9.09627 11.7857 8.91459 11.7105 8.78064 11.5765C8.64668 11.4426 8.57143 11.2609 8.57143 11.0715C8.57143 10.882 8.64668 10.7003 8.78064 10.5664C8.91459 10.4324 9.09627 10.3572 9.28571 10.3572H10.7143C10.9037 10.3572 11.0854 10.4324 11.2194 10.5664C11.3533 10.7003 11.4286 10.882 11.4286 11.0715C11.4286 11.2609 11.3533 11.4426 11.2194 11.5765C11.0854 11.7105 10.9037 11.7857 10.7143 11.7857ZM16.4286 11.7857H13.5714C13.382 11.7857 13.2003 11.7105 13.0664 11.5765C12.9324 11.4426 12.8571 11.2609 12.8571 11.0715C12.8571 10.882 12.9324 10.7003 13.0664 10.5664C13.2003 10.4324 13.382 10.3572 13.5714 10.3572H16.4286C16.618 10.3572 16.7997 10.4324 16.9336 10.5664C17.0676 10.7003 17.1429 10.882 17.1429 11.0715C17.1429 11.2609 17.0676 11.4426 16.9336 11.5765C16.7997 11.7105 16.618 11.7857 16.4286 11.7857ZM1.42857 3.92861V1.78575H18.5714V3.92861H1.42857Z"
      fill="#00AE73"
    />
  </Svg>
);

export default Icon;
